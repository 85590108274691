/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import "leaflet/dist/leaflet.css";
import VueAxios from 'vue-axios';
import axios from "axios";



window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.use(VueAxios, axios);


Vue.component('map-component', require('./components/MapComponent.vue').default);

Vue.component('sidebar', require('./components/Sidebar.vue').default);

Vue.component('chart', require('./components/Chart.vue').default);

Vue.component('gauge', require('./components/Gauge.vue').default);

Vue.component('station-details', require('./components/StationDetails.vue').default);

Vue.component('measurement-row', require('./components/MeasurementRow.vue').default);

Vue.component('compass', require('./components/Compass.vue').default);

Vue.component('admin-nodes-list', require('./components/AdminNodesList.vue').default);
Vue.component('admin-users-list', require('./components/AdminUsersList.vue').default);

Vue.component('edit-node-modal', require('./components/EditNodeModal.vue').default);
Vue.component('edit-user-modal', require('./components/EditUserModal.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
export const bus = new Vue();

const app = new Vue({
    el: '#app',
});
