<template>
    <div class="container">
        <div class="row" style="margin-top:10px;margin-bottom: 5px;">
            <div class="col-4">
                <button type="button" @click='open_create_node_form' id="btn_open_form" class="btn btn-primary">New Node</button>
            </div>
            <div class="col-4">
                <button type="button" @click='save_node()' id='btn_save_node' class="btn btn-success d-none">Save Node</button>
            </div>
            <div class="col-4">
                <button type="button" @click='close_create_node_form()' id='btn_cancel' class="btn btn-danger d-none">Cancel</button>
            </div>
        </div>
        <div class="row d-none" id="form_new_node" style="border: 1px solid black;margin-top:10px; margin-bottom: 10px;padding-top:10px;">
            <div class="col-12"><hr/></div>
            <div class="col-12">
                    <div class="form-group">
                        <label for="create_node_name">Name</label>
                        <input type="text" class="form-control" id="create_node_name" placeholder="Name">
                    </div>
                    <div class="form-group">
                        <label for="create_node_imei">IMEI</label>
                        <input type="text" class="form-control" id="create_node_imei" placeholder="IMEI">
                    </div>
            </div>
        </div>
        <div class="row" id="desktop_table">
            <div class="table-responsive table-bordered movie-table">

                <table class="table" style="margin-bottom: 100px;">
                    <thead>
                    <tr class= "">
                        <th>Name</th>
                        <th>Imei</th>
                        <th>Users</th>
                        <th>###</th>
                        <th>###</th>
                    </tr>
                    </thead>
                    <tbody>

                    <!--row-->
                    <tr class="dark-row"  v-for="node in nodes">
                        <td>{{ node.name || "Null" }}</td>
                        <td> {{ node.imei }} </td>
                        <td>
                            <div v-for="user in node.users">{{user.email}}</div>
                        </td>
                        <td><a data-toggle="modal" :data-target="'#editNode_' + node.imei">Edit</a></td>
                        <td><button type="button" @click="deleteNode(node.imei)" class="btn btn-danger">Delete</button></td>
                    </tr>
                    <!--/.row-->



                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" id="mobile_table">
            <div style="padding-top: 15px;margin-bottom: 50px;">

                <table v-for="node in nodes"  style="margin-bottom: 15px;border: 1px solid black;width:100%;">
                    <tr>
                        <td style="padding-top:5px;padding-bottom: 5px;" colspan="2">{{node.name}}</td>
                    </tr>
                    <tr>
                        <td style="padding-top:5px;padding-bottom: 5px;" colspan="2">{{node.imei}}</td>
                    </tr>
                    <tr>
                        <td style="padding-top:5px;padding-bottom: 5px;" colspan="2">
                            <div v-for="user in node.users">{{user.email}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-top:5px;padding-bottom: 5px;" colspan="2">
                            <hr/>
                        </td>
                    </tr>
                    <tr >
                        <td style="padding-top:0;padding-bottom: 5px;width:50%;text-align:center;"><a data-toggle="modal" :data-target="'#editNode_' + node.imei">Edit</a></td>
                        <td style="padding-top:0;padding-bottom: 5px;width:50%;text-align:center;">
                            <button type="button" @click="deleteNode(node.imei)" class="btn btn-danger" style="width: 100%;">Delete</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <edit-node-modal v-for="node in nodes" :key="node.imei" :node="node" :users="users" :ref="'editNode_' + node.imei"></edit-node-modal>
    </div>
</template>

<script>
import {bus} from "../app";

export default {
    data: function () {
        return {
            nodes: [],
            users: []
        }
    },
    mounted() {
        let self = this;
        self.getData();
        self.getUsers();
        self.initResponsive()
        $(window).resize(function() {
            self.initResponsive()
        });
    },
    methods:{
        initResponsive(){
            let width = $(window).width();
            if(width < 768){
                $('#desktop_table').hide();
                $('#mobile_table').show();
            }else{
                $('#desktop_table').show();
                $('#mobile_table').hide();
            }
        },
        getData()
        {
            this.axios
                .get('/admin/nodes')
                .then(response => {
                    this.nodes = response.data;
                })
        },
        getUsers()
        {
            let self = this;
            this.axios
                .get('/admin/users')
                .then(response => {
                    self.users = response.data;
                })
        },
        deleteNode(node_id)
        {
            if(confirm("You are about to delete "+node_id+", Are you sure?")){

                this.axios
                    .delete('/admin/node/'+node_id)
                    .then(response => {
                        this.getData();
                    })
            }
        },
        open_create_node_form() {
            $('#btn_open_form').addClass('d-none')
            $('#btn_save_node').removeClass('d-none')
            $('#btn_cancel').removeClass('d-none')
            $('#form_new_node').removeClass('d-none')
        },
        save_node(){
            let self = this;
            let name = $('#create_node_name').val();
            let imei = $('#create_node_imei').val();
            let user_id = $('#selected_user').find(":selected").val();
            this.axios
                .post('/admin/node-create',
                    {
                        name : name,
                        imei : imei,
                        user_id : user_id,
                    })
                .then(response => {
                    self.init_create_field();
                    self.close_create_node_form();
                    self.getData()
                }).catch(err => {
                    alert(err.response.data.msg)
                });
        },
        close_create_node_form(){
            $('#btn_open_form').removeClass('d-none')
            $('#btn_save_node').addClass('d-none')
            $('#btn_cancel').addClass('d-none')
            $('#form_new_node').addClass('d-none')
        },
        init_create_field(){
            $('#create_node_name').val('');
            $('#create_node_imei').val('');
        }
    }
}
</script>
<style scoped>
.active-row {
    background-color: #2fa360;
    font-weight: bold;
}
.inactive-row {
    background-color: #FFB2B2;
    font-weight: bold;
    color: white;
}

</style>
