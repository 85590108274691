<template>
    <div class="modal fade" :id="'editNode_' + node.imei" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Node Edit</h5>
                    <button type="button" class="close" @click="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form method="POST">
                <div class="modal-body">

                    <div class="form-check" style="text-align: left;">
                        <label style="text-align: left;">Altitude (m) <input v-model="altitude_in_m" type="number" value="" pattern="/^\d*(\.\d{0,2})?$"  /></label>
                        <button v-on:click="clear_altitude">C</button>
                    </div>
                    <input id="name" type="text" class="form-control" name="fname" placeholder="Name" v-model="nodeName">
                    <input id="imei" type="text" class="form-control" name="fname" placeholder="imei" :value="node.imei"  disabled>
                    <div class="row" style="border-bottom: 1px solid black">
                        <span style="font-weight:bold;">Node Users</span>
                    </div>
                    <div class="form-group">
                        <div class="row" v-for="user in nodeUsers" style="margin-bottom: 5px;margin-top:5px;">
                            <div class="col-8">
                                {{user.email}}
                            </div>
                            <div class="col-4">
                                <button class="btn btn-sm btn-danger" @click="removeUserFromNode($event, user.id)" style="margin-top:5px;margin-bottom: 5px;">x</button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <button @click="addUserContainer">Available Users</button>
                    </div>
                    <div class="row" v-for="available_user in availableUsers">
                        <div class="col-8">
                            {{available_user.email}}
                        </div>
                        <div class="col-4">
                            <button class="btn btn-sm btn-success" @click="addUserToNode($event, available_user.id)"style="margin-top:5px;margin-bottom: 5px;">+</button>
                        </div>
                    </div>
                    <div class="d-none" :id="'available_users_container_'+$vnode.key">
<!--                        <div class="row" style="border-bottom: 1px solid black">-->
<!--                            <span style="font-weight:bold;">Available Users</span>-->
<!--                        </div>-->

                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="editNode">Save changes</button>
                    <button type="button" class="btn btn-secondary" @click="close" >Close</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from "../app";

export default {
    props: [ 'node', 'users' ],
    data() {
        return {
            nodeName: this.node.name,
            nodeUsers: this.node.users,
            availableUsers: [],
            firstLoad: true,
            collectAddUserId:[],
            collectRemoveUserId:[],
            altitude_in_m: this.node.altitude_in_cm === null?null:(this.node.altitude_in_cm / 100)
        }
    },
    // created() {
    //     // this.nodeUsers[this.$vnode.key] = this.node.users;
    // },
    // created(){
    //     let node_users_id = []
    //     this.nodeUsers.forEach(function(user){
    //         node_users_id.push(user.id);
    //     })
    //     console.log(this.users);
    //     let tmpAvailableUsers = [];
    //     this.users.forEach(function(u){
    //         if(!this.inArray(u.id, node_users_id)){
    //             tmpAvailableUsers.push(u);
    //         }
    //     })
    //     this.availableUsers = tmpAvailableUsers;
    // },
    // computed(){
    //     this.availableUsers[this.node.imei] = this.users;
    // },
    methods: {
        addUserContainer(e){
            e.preventDefault();
            let self = this;
            let node_users_id = []
            self.nodeUsers.forEach(function(user){
                node_users_id.push(user.id);
            })
            console.log(this.users);
            let tmpAvailableUsers = [];
            self.users.forEach(function(u){
                if(!self.inArray(u.id, node_users_id)){
                    tmpAvailableUsers.push(u);
                }
            })
            this.availableUsers = tmpAvailableUsers;
            this.firstLoad = false;
        },
        removeUserFromNode(e, user_id){
            e.preventDefault();
            if(this.inArray(user_id,this.collectAddUserId)){
                let tmpArr = [];
                this.collectAddUserId.forEach(function(value, index){
                    if(value!==user_id){
                        tmpArr.push(value)
                    }
                })
                this.collectAddUserId = tmpArr
            }

            if(!this.inArray(user_id,this.collectRemoveUserId)){
                this.collectRemoveUserId.push(user_id);
            }

            let self = this;
            let tmpUsers = [];
            if(this.firstLoad){
                let node_users_id = []
                self.nodeUsers.forEach(function(user){
                    node_users_id.push(user.id);
                })
                let tmpAvailableUsers = [];
                self.users.forEach(function(u){
                    if(!self.inArray(u.id, node_users_id)){
                        tmpAvailableUsers.push(u);
                    }
                })
                this.availableUsers = tmpAvailableUsers;
            }

            this.nodeUsers.forEach(function(u){
                if(u.id !== user_id){
                    tmpUsers.push(u);
                }else{
                    self.availableUsers.push(u)
                }
            })
            this.nodeUsers = tmpUsers;
            this.firstLoad = false;
        },
        addUserToNode(e, user_id){
            e.preventDefault();
            if(this.inArray(user_id,this.collectRemoveUserId)){
                let tmpArr = [];
                this.collectRemoveUserId.forEach(function(value, index){
                    if(value!==user_id){
                        tmpArr.push(value)
                    }
                })
                this.collectRemoveUserId = tmpArr;
            }

            if(!this.inArray(user_id,this.collectAddUserId)){
                this.collectAddUserId.push(user_id);
            }
            let self = this;
            let tmpUsers = [];
            this.availableUsers.forEach(function(u){
                if(u.id !== user_id){
                    tmpUsers.push(u);
                }else{
                    self.nodeUsers.push(u)
                }
            })
            this.availableUsers = tmpUsers;
            this.firstLoad = false;
        },
        inArray(needle, haystack) {
            let length = haystack.length;
            for(var i = 0; i < length; i++) {
                if(haystack[i] === needle) return true;
            }
            return false;
        },
        editNode() {
            let self = this;

            self.axios
                .post('/admin/node-update',
                { imei : this.node.imei,
                        name : this.nodeName,
                        removeUsers: this.collectRemoveUserId,
                        addUsers: this.collectAddUserId,
                        altitude_in_cm : this.altitude_in_m!==null?(this.altitude_in_m * 100):null,
                     })
                .then(response => {
                    self.close()
                })
        },
        clear_altitude:function(e){
            e.preventDefault();
            this.altitude_in_m = null
        },
        close:function (){
            location.reload();
        },
    }
}
</script>
