<template>
    <div class="modal fade" :id="'editUser_' + user.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">User Edit</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form method="POST">
                    <div class="modal-body">

                        <div class="form-group">
                            <label for="edit_user_name">Name</label>
                            <input type="text" class="form-control" :id="'edit_user_name_'+user.id" v-model="name" placeholder="Name" >
                        </div>
                        <div class="form-group">
                            <label for="edit_user_email">E-Mail</label>
                            <input type="text" class="form-control" :id="'edit_user_email_'+user.id" v-model="email" placeholder="E-mail" readonly>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="saveUser">Save changes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from "../app";

export default {
    props: [ 'user' ],
    data() {
        return {
            id: this.user.id,
            name: this.user.name,
            email: this.user.email,
            password: ''
        }
    },
    methods: {
        saveUser() {
            let self = this;
            let data = {
                id: this.id,
                name: this.name,
                email: this.email
            }
            if(this.password.length > 0 && this.validate_password(this.password)){
                data['password'] = this.password;
            }
            self.axios
                .post('/admin/user-update',data)
                .then(response => {
                    self.close()
                }).catch(err => {
                alert(err.response.data.msg)
            });
        },
        validate_password(password){
            let validPassword = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&_*]{6,16}$/;
            return password.match(validPassword)
        },
        close:function (){
            $('#editUser_'+this.id).modal('hide');
            bus.$emit('modal_user_closed',true)
        },
    }
}
</script>
