<template>
    <div class="container">
        <div class="row" style="margin-top:10px;margin-bottom: 5px;">
            <div class="col-4">
                <button type="button" @click='open_create_user_form' id="btn_open_form" class="btn btn-primary">New User</button>
            </div>
            <div class="col-4">
                <button type="button" @click='save_user()' id='btn_save_user' class="btn btn-success d-none">Save User</button>
            </div>
            <div class="col-4">
                <button type="button" @click='close_create_user_form()' id='btn_cancel' class="btn btn-danger d-none">Cancel</button>
            </div>
        </div>
        <div class="row d-none" id="form_new_user" style="border: 1px solid black;margin-top:10px; margin-bottom: 10px;padding-top:10px;">
            <div class="col-12"><hr/></div>
            <div class="col-12">
                <div class="form-group">
                    <label for="create_user_name">Name</label>
                    <input type="text" class="form-control" id="create_user_name" placeholder="Name">
                </div>
                <div class="form-group">
                    <label for="create_user_email">E-Mail</label>
                    <input type="text" class="form-control" id="create_user_email" placeholder="E-mail">
                </div>
            </div>
        </div>
        <div class="row" id="desktop_table">
            <div class="table-responsive table-bordered movie-table">

                <table class="table" style="margin-bottom: 100px;">
                    <thead>
                    <tr class= "">
                        <th>Name</th>
                        <th>Email</th>
                        <th>Verify</th>
                        <th>Reset Password</th>
                        <th>###</th>
                        <th>###</th>
                    </tr>
                    </thead>
                    <tbody>

                    <!--row-->
                    <tr class="dark-row"  v-for="user in users">
                        <td>{{ user.name }}</td>
                        <td> {{ user.email }} </td>
                        <td>
                            <button v-if="user.email_verified_at === null && user.role === 1" type="button" @click="verifyUser(user.email)" class="btn btn-success">Verify</button>
                        </td>
                        <td>
                            <button v-if="user.role === 1" type="button" @click="resetPwd(user.email)" class="btn btn-success">Reset Password</button>
                        </td>
                        <td>
                            <a data-toggle="modal" :data-target="'#editUser_' + user.id">Edit</a>
                        </td>
                        <td>
                            <button v-if="user.role === 1" type="button" @click="deleteUser(user.id)" class="btn btn-danger">Delete</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div >
        <div class="row" id="mobile_table">
            <div style="padding-top: 15px;margin-bottom: 50px;">

                <table v-for="user in users"  style="margin-bottom: 15px;border: 1px solid black;width:100%;">
                    <tr>
                        <td colspan="4">{{user.email}} - {{user.name}}</td>
                    </tr>
                    <tr>
                        <td style="padding-top:5px;padding-bottom: 5px;text-align: center;width: 25%;">
                            <button v-if="user.email_verified_at === null && user.role === 1" type="button" @click="verifyUser(user.email)" class="btn btn-success" style="width: 100%;">Verify</button>
                        </td>
                        <td style="padding-top:5px;padding-bottom: 5px;text-align: center;width: 25%;">
                            <button v-if="user.role === 1" type="button" @click="resetPwd(user.email)" class="btn btn-success" style="width: 100%;">Password</button>
                        </td>
                        <td style="padding-top:5px;padding-bottom: 5px;text-align: center;width: 25%;">
                            <a data-toggle="modal" :data-target="'#editUser_' + user.id">Edit</a>
                        </td>
                        <td style="padding-top:5px;padding-bottom: 5px;text-align: center;width: 25%;">
                            <button v-if="user.role === 1" type="button" @click="deleteUser(user.id)" class="btn btn-danger">Delete</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <edit-user-modal v-for="user in this.users" :key="user.id" :user="user"></edit-user-modal>
    </div>
</template>

<script>
import {bus} from "../app";

export default {
    data: function () {
        return {
            users: []
        }
    },
    created() {
        bus.$on('modal_user_closed', () => {
            this.init_create_field();
            this.close_create_user_form();
            this.users = [];
            this.getUsers()
        });
    },
    mounted() {
        let self = this;
        self.getUsers();
        self.initResponsive()
        $(window).resize(function() {
            self.initResponsive()
        });
    },
    methods:{
        initResponsive(){
            let width = $(window).width();
            if(width < 768){
                $('#desktop_table').hide();
                $('#mobile_table').show();
            }else{
                $('#desktop_table').show();
                $('#mobile_table').hide();
            }
        },
        resetPwd(email){
            this.axios
                .post('/admin/reset/user',{
                    email : email
                }).then(response => {})
        },
        verifyUser(email){
            this.axios
                .post('/admin/verify/user',{
                    email : email
                }).then(response => {})
        },
        getUsers(){
            this.axios
                .get('/admin/users')
                .then(response => {
                    this.users = response.data;
                })
        },
        open_create_user_form() {
            $('#btn_open_form').addClass('d-none')
            $('#btn_save_user').removeClass('d-none')
            $('#btn_cancel').removeClass('d-none')
            $('#form_new_user').removeClass('d-none')
        },
        close_create_user_form(){
            $('#btn_open_form').removeClass('d-none')
            $('#btn_save_user').addClass('d-none')
            $('#btn_cancel').addClass('d-none')
            $('#form_new_user').addClass('d-none')
        },
        save_user(){
            console.log('save user');
            let name = $('#create_user_name').val();
            let email = $('#create_user_email').val();
            let password = $('#create_user_password').val();
            if(!this.validate_email(email)){
                alert('Not a valid email!');
                return;
            }
            let self = this;
            this.axios
                .post('/admin/user-create',
                    {
                        name : name,
                        email : email,
                    })
                .then(response => {
                    window.location.reload();
                }).catch(err => {
                alert(err.response.data.msg)
            });
        },
        deleteUser(id){
            if(confirm('You want to delete this user')){
                let self = this;
                this.axios
                    .delete('/admin/user-delete/'+id)
                    .then(response => {
                        self.init_create_field();
                        self.close_create_user_form();
                        self.getUsers()
                    }).catch(err => {
                    alert(err.response.data.msg)
                });
            }
        },
        validate_password(password){
            let validPassword = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&_*]{6,16}$/;
            return password.match(validPassword)
        },
        validate_email(email){
            let validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return email.match(validEmail)
        },
        init_create_field(){
            $('#create_user_name').val('');
            $('#create_user_email').val('');
            $('#create_user_password').val('')
        }
    }
}
</script>
