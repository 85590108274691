<template>
    <div class="hello" ref="chartdiv">
    </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
    name: 'Gauge',
    props: [ 'sensorReadings', 'type' ],
    data() {
        return {}
    },
    methods: {
        createChart() {
            am4core.useTheme(am4themes_animated);
            let chart = am4core.create(this.$refs.chartdiv, am4charts.GaugeChart);
            let axis = chart.xAxes.push(new am4charts.ValueAxis());


            axis.strictMinMax = true;
            chart.innerRadius = -20;
            // Add ranges
            let range = axis.axisRanges.create();
            range.axisFill.fillOpacity = 1;
            range.axisFill.zIndex = - 1;

            let range2 = axis.axisRanges.create();
            range2.axisFill.fillOpacity = 1;
            range2.axisFill.zIndex = - 1;

            let range3 = axis.axisRanges.create();
            range3.axisFill.fillOpacity = 1;
            range3.axisFill.zIndex = - 1;

            let range4 = axis.axisRanges.create();
            range4.axisFill.fillOpacity = 1;
            range4.axisFill.zIndex = - 1;

            // Add hand
            let hand = chart.hands.push(new am4charts.ClockHand());
            hand.pin.disabled =  true;
            hand.fill = am4core.color("#2D93AD");
            hand.stroke = am4core.color("#2D93AD");
            hand.innerRadius = am4core.percent(50);
            hand.radius = am4core.percent(80);
            hand.startWidth = 15;

            var label = chart.radarContainer.createChild(am4core.Label);
            label.isMeasured = false;
            label.fontSize = 25;
            label.x = am4core.percent(50);
            label.y = am4core.percent(80);
            label.horizontalCenter = "middle";
            label.verticalCenter = "bottom";

            if (this.type === 'heat_index') {
                axis.min = -20;
                axis.max = 50;
                range.axisFill.fill = am4core.color("#4c59ac");
                range.value = -20;
                range.endValue = 0;
                range2.axisFill.fill = am4core.color("rgba(32,184,32,0.84)");
                range2.value = 0;
                range2.endValue = 25;
                range3.axisFill.fill = am4core.color("rgba(231,125,53,0.93)");
                range3.value = 25;
                range3.endValue = 40;
                range4.axisFill.fill = am4core.color("rgba(234,39,39,0.95)");
                range4.value = 40;
                range4.endValue = 50;

                hand.value = this.sensorReadings[0].heat_index;
                label.text = this.sensorReadings[0].heat_index + '°C';
            }else if (this.type === 'dew_point') {
                axis.min = -20;
                axis.max = 50;
                range.axisFill.fill = am4core.color("#4c59ac");
                range.value = -20;
                range.endValue = 0;
                range2.axisFill.fill = am4core.color("rgba(32,184,32,0.84)");
                range2.value = 0;
                range2.endValue = 25;
                range3.axisFill.fill = am4core.color("rgba(231,125,53,0.93)");
                range3.value = 25;
                range3.endValue = 40;
                range4.axisFill.fill = am4core.color("rgba(234,39,39,0.95)");
                range4.value = 40;
                range4.endValue = 50;

                hand.value = this.sensorReadings[0].dew_point;
                label.text = this.sensorReadings[0].dew_point + '°C';
            }else if (this.type === 'wind_chill') {
                axis.min = -20;
                axis.max = 50;
                range.axisFill.fill = am4core.color("#4c59ac");
                range.value = -20;
                range.endValue = 0;
                range2.axisFill.fill = am4core.color("rgba(32,184,32,0.84)");
                range2.value = 0;
                range2.endValue = 25;
                range3.axisFill.fill = am4core.color("rgba(231,125,53,0.93)");
                range3.value = 25;
                range3.endValue = 40;
                range4.axisFill.fill = am4core.color("rgba(234,39,39,0.95)");
                range4.value = 40;
                range4.endValue = 50;

                hand.value = this.sensorReadings[0].wind_chill;
                label.text = this.sensorReadings[0].wind_chill + '°C';
            }else if (this.type === 'temp') {
                axis.min = -20;
                axis.max = 50;
                range.axisFill.fill = am4core.color("#4c59ac");
                range.value = -20;
                range.endValue = 0;
                range2.axisFill.fill = am4core.color("rgba(32,184,32,0.84)");
                range2.value = 0;
                range2.endValue = 25;
                range3.axisFill.fill = am4core.color("rgba(231,125,53,0.93)");
                range3.value = 25;
                range3.endValue = 40;
                range4.axisFill.fill = am4core.color("rgba(234,39,39,0.95)");
                range4.value = 40;
                range4.endValue = 50;

                hand.value = this.sensorReadings[0].temperature;
                label.text = this.sensorReadings[0].temperature + '°C';
            } else if (this.type === 'hum') {
                axis.min = 0;
                axis.max = 100;    range.axisFill.fill = am4core.color("rgba(76,89,172,0.24)");
                range.value = 0;
                range.endValue = 20;
                range2.axisFill.fill = am4core.color("rgba(76,89,172,0.46)");
                range2.value = 20;
                range2.endValue = 40;
                range3.axisFill.fill = am4core.color("rgba(76,89,172,0.76)");
                range3.value = 40;
                range3.endValue = 70;
                range4.axisFill.fill = am4core.color("rgb(76,89,172)");
                range4.value = 70;
                range4.endValue = 100;

                hand.value = this.sensorReadings[0].humidity;
                label.text = this.sensorReadings[0].humidity + '%';
            } else if (this.type === 'precip') {
                axis.min = 0;
                axis.max = 400;    range.axisFill.fill = am4core.color("rgba(76,89,172,0.24)");
                range.value = 0;
                range.endValue = 100;
                range2.axisFill.fill = am4core.color("rgba(76,89,172,0.46)");
                range2.value = 100;
                range2.endValue = 200;
                range3.axisFill.fill = am4core.color("rgba(76,89,172,0.76)");
                range3.value = 200;
                range3.endValue = 300;
                range4.axisFill.fill = am4core.color("rgb(76,89,172)");
                range4.value = 300;
                range4.endValue = 400;


                hand.value = Math.round(this.sensorReadings.map(function(o) {
                    return o.rainfall;
                }).reduce((a,b) => a + b, 0) * 100) /100;
                label.text = (Math.round(hand.value * 100) / 100).toFixed(2) + ' mm';
            }else if (this.type === 'wind') {
                axis.min = 0;
                axis.max = 180;    range.axisFill.fill = am4core.color("rgba(236,166,16,0.93)");
                range.value = 0;
                range.endValue = 40;
                range2.axisFill.fill = am4core.color("rgba(255,98,0,0.8)");
                range2.value = 40;
                range2.endValue = 60;
                range3.axisFill.fill = am4core.color("rgba(255,8,8,0.8)");
                range3.value = 60;
                range3.endValue = 135;
                range4.axisFill.fill = am4core.color("rgba(255,8,8,0.8)");
                range4.value = 135;
                range4.endValue = 180;

                hand.value = this.sensorReadings[0].wind_speed;
                label.text = this.sensorReadings[0].wind_speed + ' km/h \n';

                let label1 = chart.radarContainer.createChild(am4core.Label);
                label1.isMeasured = false;
                label1.fontSize = 25;
                label1.x = am4core.percent(0);
                label1.y = am4core.percent(0);
                label1.horizontalCenter = "middle";
                label1.text = this.convertSpeedToBeaufort(this.sensorReadings[0].wind_speed) + ' Beaufort';
            } else if (this.type === 'gust') {
                axis.min = 0;
                axis.max = 180;    range.axisFill.fill = am4core.color("rgba(236,166,16,0.93)");
                range.value = 0;
                range.endValue = 40;
                range2.axisFill.fill = am4core.color("rgba(255,98,0,0.8)");
                range2.value = 40;
                range2.endValue = 60;
                range3.axisFill.fill = am4core.color("rgba(255,8,8,0.8)");
                range3.value = 60;
                range3.endValue = 135;
                range4.axisFill.fill = am4core.color("rgba(255,8,8,0.8)");
                range4.value = 135;
                range4.endValue = 180;

                hand.value = this.sensorReadings[0].gust_speed;
                label.text = '   ' + this.sensorReadings[0].gust_speed + ' km/h';

                let label1 = chart.radarContainer.createChild(am4core.Label);
                label1.isMeasured = false;
                label1.fontSize = 25;
                label1.x = am4core.percent(0);
                label1.y = am4core.percent(0);
                label1.horizontalCenter = "middle";
                label1.text = this.convertSpeedToBeaufort(this.sensorReadings[0].gust_speed) + ' Beaufort';

            } else if (this.type === 'light') {
                axis.min = 0;
                axis.max = 1000;
                range.axisFill.fill = am4core.color("rgba(76,89,172,0.24)");
                range.value = 0;
                range.endValue = 250;
                range2.axisFill.fill = am4core.color("rgba(76,89,172,0.46)");
                range2.value = 250;
                range2.endValue = 500;
                range3.axisFill.fill = am4core.color("rgba(76,89,172,0.76)");
                range3.value = 500;
                range3.endValue = 750;
                range4.axisFill.fill = am4core.color("rgb(76,89,172)");
                range4.value = 750;
                range4.endValue = 1000;

                hand.value = this.sensorReadings[0].light_intensity;
                label.html = this.sensorReadings[0].light_intensity + ' W/m<sup>2</sup>';
            } else if (this.type === 'uv') {
                axis.min = 0;
                axis.max = 13;
                range.axisFill.fill = am4core.color("rgb(15,227,65)");
                range.value = 0;
                range.endValue = 2;
                range2.axisFill.fill = am4core.color("rgba(241,203,0,0.88)");
                range2.value = 2;
                range2.endValue = 5;
                range3.axisFill.fill = am4core.color("rgba(236,89,17,0.94)");
                range3.value = 5;
                range3.endValue = 7;
                range4.axisFill.fill = am4core.color("rgb(224,10,10)");
                range4.value = 7;
                range4.endValue = 10;

                let range5 = axis.axisRanges.create();
                range5.axisFill.fillOpacity = 1;
                range5.axisFill.zIndex = - 1;
                range5.value = 10;
                range5.endValue = 13;
                range5.axisFill.fill = am4core.color("rgb(111,9,130)");

                hand.value = this.sensorReadings[0].uv_index;
                label.text = this.sensorReadings[0].uv_index + ' UV index';
            } else if (this.type === 'pressure') {
                axis.min = 700;
                axis.max = 1100;
                range.axisFill.fill = am4core.color("rgba(76,89,172,0.24)");
                range.value = 700;
                range.endValue = 800;
                range2.axisFill.fill = am4core.color("rgba(76,89,172,0.46)");
                range2.value = 800;
                range2.endValue = 900;
                range3.axisFill.fill = am4core.color("rgba(76,89,172,0.76)");
                range3.value = 900;
                range3.endValue = 1000;
                range4.axisFill.fill = am4core.color("rgb(76,89,172)");
                range4.value = 1000;
                range4.endValue = 1100;

                hand.value = this.sensorReadings[0].pressure;
                label.text = this.sensorReadings[0].pressure + ' hPa';

                if(this.sensorReadings[0].relative_pressure !== undefined){
                    hand.value = this.sensorReadings[0].relative_pressure;
                    label.text = this.sensorReadings[0].relative_pressure + ' hPa';
                }
            }


        },
        convertSpeedToBeaufort(wind_speed) {
            let beaufort = 0;

            if (wind_speed >= 1.1 && wind_speed < 5.6)
                beaufort = 1;
            else if (wind_speed >= 5.6 && wind_speed < 12)
                beaufort = 2;
            else if (wind_speed >= 12 && wind_speed < 20)
                beaufort = 3;
            else if (wind_speed >= 20 && wind_speed < 29)
                beaufort = 4;
            else if (wind_speed >= 29 && wind_speed < 39)
                beaufort = 5;
            else if (wind_speed >= 39 && wind_speed < 50)
                beaufort = 6;
            else if (wind_speed >= 50 && wind_speed < 62)
                beaufort = 7;
            else if (wind_speed >= 62 && wind_speed < 75)
                beaufort = 8;
            else if (wind_speed >= 75 && wind_speed < 89)
                beaufort = 9;
            else if (wind_speed >= 89 && wind_speed < 103)
                beaufort = 10;
            else if (wind_speed >= 103 && wind_speed < 118)
                beaufort = 11;
            else if (wind_speed >= 118)
                beaufort = 12;

            return beaufort;
        }
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    },
    mounted() {
        this.createChart()
    },
}
</script>

<style scoped>
.hello {
    width: 80%;
    height: 300px;
    margin-top: 10px;
}
@media all and (max-width: 800px) {
    .hello {
        width: 100%;
        height: 200px;
        margin-top: 10px;
    }

}
</style>
